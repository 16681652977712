:root {
  --light: #fff;
  --dark: #111;
  --grey: #777;
  --main: #6f29d9;
  --main-light: #a55eea;
  --transparent: transparent;
}

::-moz-selection {
  background: var(--main);
  color: var(--light);
}

::selection {
  background: var(--main);
  color: var(--light);
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background: linear-gradient(45deg, var(--light), var(--main), var(--main-light));
  background-size: 400% 400%;
  overflow-x: hidden;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 50% 50%;
  }

  50% {
    background-position: 100% 25%;
  }

  100% {
    background-position: 50% 50%;
  }
}

#page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  min-height: 200vh;
  overflow-y: auto;
  width: 100%;
  background-color: var(--light);
  transition: all 1s ease;
}

.container,
.first {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.first {
  justify-content: center;
}

.first h1,
.first p {
  text-align: center;
}

.first .scroll-down {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: .1em solid var(--main);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: scroll 2s ease infinite;
  cursor: pointer;
  transition: all .5s ease;
}

.first .scroll-down:hover {
  background-color: var(--main);
}

.first .scroll-down i {
  color: var(--main);
}

.first .scroll-down:hover i {
  color: var(--light);
}

@keyframes scroll {
  0% {
    transform: translateX(-50%) translateY(0);
  }

  50% {
    transform: translateX(-50%) translateY(10px);
  }

  100% {
    transform: translateX(-50%) translateY(0);
  }
}

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow-x: hidden;
}

.container .child .box {
  padding: 25px;
  overflow-y: auto;
  background-color: var(--light);
  border-radius: 25px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.container .child .box h2 {
  font-size: 2rem;
  color: var(--main);
  margin: 0;
  padding: 0;
}

.container .child .box p {
  font-size: 1em;
  color: var(--grey);
  margin: 0;
  padding: 0;
}

.container .child .box iframe body {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.container .child .box iframe body::-webkit-scrollbar {
  display: none !important;
}

@media only screen and (min-width: 768px) {

  .container .child {
    width: 50vw;
    height: 100%;
  }

  .container .child .box {
    margin: 10%;
    height: calc(80% - 50px);
  }

  .first h1 {
    font-size: 4em;
  }

  .scores .score {
    margin: 1em 4em;
    padding: 1em;
  }

  .scores .score h3,
  .scores .score p {
    margin-top: .5em;
  }

  .scores .score i {
    margin-top: .5em !important;
    margin-bottom: 1em !important;
  }

  .scores .score .circle_bar_animation {
    width: 200px;
    height: 200px;
    margin: 2em auto;
  }

  .scores .score .circle_bar_animation .progress .progress-value {
    font-size: 2em;
  }

}

@media only screen and (max-width: 480px) {

  .container .child {
    width: 100vw;
    height: 100%;
  }

  .container .child .box {
    margin: 4%;
    height: calc(92% - 25px);
  }

  .first h1 {
    font-size: 2em;
  }

  .scores .score {
    margin: 1em;
    padding: .7em;
  }

  .scores .score h3,
  .scores .score p,
  .scores .score i {
    margin-top: .3em;
  }

  .scores .score .circle_bar_animation {
    width: 100px;
    height: 100px;
    margin: 1em auto;
  }

  .scores .score .circle_bar_animation .progress .progress-value {
    font-size: 1.4em;
  }

}

.first h1 {
  color: var(--main);
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.service {
  margin: .5em auto;
}

.service h3 {
  font-size: 1.2em;
  color: var(--dark);
  margin: 0;
  margin-top: .5em;
  padding: 0;
}

.service p {
  font-size: 1em;
  color: var(--grey);
  margin: 0;
  margin-top: .5em;
  padding: 0;
}

.service p h4 {
  font-size: 1em;
  color: var(--grey);
  font-weight: bold;
  margin: .5em 0;
  padding: 0;
}

.service p a {
  color: var(--main-light);
  text-decoration: none !important;
}

.service i {
  font-size: 2em;
  color: var(--main);
  margin: 0;
  margin-top: 1em;
  padding: 0;
}

.calendly-inline-widget {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  height: calc(100% - 50px);
  align-items: center;
  align-content: center;
  justify-content: center;
}

.social {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 350px;
  display: grid;
  gap: 1em;
  flex-direction: row;
  text-align: left;
}

.social a {
  margin: 0 auto;
  color: var(--main);
  text-decoration: none;
  transition: all .5s ease;
}

.social a i {
  font-size: 1.2em;
  margin-right: .5em;
}

.button {
  position: relative;
  margin: 5em auto;
  padding: .75em;
  width: 100%;
  font-size: 1.2em;
  max-width: 300px;
  display: flex;
  flex-wrap: nowrap;
  border-radius: 25px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  transition: all .5s ease;
}

.button .arrow-icon {
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  font-size: 1.5em;
  color: var(--light);
  transition: all .5s ease;
}

.button:hover .arrow-icon {
  transform: translateY(-50%) translateX(5px);
}

.main {
  background-color: var(--main);
  color: var(--light);
  justify-content: center;
}

.main:hover {
  background-color: var(--main-light);
}

.light {
  background-color: var(--light);
  color: var(--main);
}

.light:hover {
  background-color: var(--main);
  color: var(--light);
}

.scores {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.scores .score {
  width: 100%;
  max-width: 320px;
  display: grid;
  text-align: center;
  background-color: var(--light);
  border-radius: 25px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.scores .score h3 {
  font-size: 1.2em;
  color: var(--main);
  margin: 0;
  padding: 0;
}

.scores .score p {
  font-size: 1em;
  color: var(--grey);
  margin: 0;
  padding: 0;
}

.scores .score i {
  font-size: 2em;
  color: var(--main);
  margin: 0;
  padding: 0;
}

.scores .score .circle_bar_animation {
  position: relative;
  border-radius: 50%;
  background-color: var(--main);
  overflow: hidden;
}

.scores .score .circle_bar_animation .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.scores .score .circle_bar_animation .progress .progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

#circle1,
#circle2,
#circle3 {
  background:
    radial-gradient(closest-side, var(--light) 79%, var(--transparent) 80% 100%),
    conic-gradient(var(--main) 100%, var(--main-light) 0);
  filter: saturate(0);
}

#value1,
#value2,
#value3 {
  filter: saturate(0);
}

.scores .score .circle_bar_animation .progress .progress-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--main);
}